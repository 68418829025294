<template>
  <v-dialog
    v-model="showEditEmailConfProps"
    persistent
    width="500"
    @click:outside="close"
  >
    <v-form lazy-validation ref="edit-email-conf" v-model="valid">
      <v-card :loading="loading">
        <v-card-title>
          <span class="text-h6"
            >Edit Email Configuration:
            <v-chip color="indigo" dark>{{ editPayload.name }} </v-chip>
          </span>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="editPayload.name"
                :rules="[rules.required]"
                color="deep-purple"
                label="Name"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="editPayload.type"
                :rules="[rules.required]"
                color="deep-purple"
                label="Type"
                outlined
                hint="Enter the email type name in lower case letters"
              ></v-text-field>
            </v-col>

            <v-col
              v-for="(value, key) in editPayload.credentials"
              :key="key"
              cols="12"
            >
              <v-text-field
                :label="key"
                v-model="editPayload.credentials[key]"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close" :disabled="loading">
            Cancel
          </v-btn>
          <v-btn
            :disabled="!valid || loading"
            color="blue darken-1"
            text
            @click="saveEditEmailConf"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { apiService } from '../../../../services/api.service'
import swal from 'sweetalert2'
export default {
  props: {
    showEditEmailConfProps: { type: Boolean, default: false },
    editFormProps: { type: Object, default: () => ({}) }
  },
  created () {
    this.editPayload = { ...this.editFormProps }
  },
  data: () => ({
    loading: false,
    valid: true,
    isJsonValid: true,
    invalidFormatMessafe: '',
    rules: {
      required: (v) => !!v || 'This field is required'
    },
    editPayload: {}
  }),

  methods: {
    async saveEditEmailConf () {
      this.loading = true
      await apiService
        .put('/admin-edit-email-configuration', this.editPayload)
        .then(() => {
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Email configuration edited successfully'
          })
          this.$emit('close')
          this.$emit('refresh')
        })
        .catch((error) => {
          console.log('error', error.response)
          swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data?.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    close () {
      this.$emit('close', this.editPayload)
    }
  }
}
</script>
