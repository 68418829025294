<template>
  <v-dialog
    v-model="showNewEmailConf"
    persistent
    width="500"
    @click:outside="close"
  >
    <v-form lazy-validation ref="new-email-conf" v-model="valid">
      <v-card :loading="loading">
        <v-card-title>
          <span class="text-h6">New Email Configuration</span>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                :rules="[rules.required]"
                color="deep-purple"
                label="Name"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.type"
                :rules="[rules.required]"
                color="deep-purple"
                label="Type"
                outlined
                hint="Enter the email type name in lower case letters"
              ></v-text-field>
            </v-col>

            <v-col>
              <v-textarea
                v-model="form.formattedCredentials"
                :rules="[rules.required]"
                name="Credentials Information"
                label="Credentials(JSON)"
                value="Test value."
                hint="Paste your JSON here"
                outlined
              ></v-textarea>

            </v-col>

            <v-col cols="12" v-if="!isJsonValid" class="mt-2">
              <v-alert dense outlined type="error">
                {{ invalidFormatMessafe }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close" :disabled="loading">
            Cancel
          </v-btn>
          <v-btn
            :disabled="!valid || loading || !isJsonValid"
            color="blue darken-1"
            text
            @click="saveNewEmailConf"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { apiService } from '../../../../services/api.service'
import swal from 'sweetalert2'
export default {
  props: {
    showNewEmailConf: { type: Boolean, default: false }
  },
  data: () => ({
    valid: true,
    loading: false,
    rules: {
      required: (v) => !!v || 'This field is required'
    },
    invalidFormatMessafe: '',
    isJsonValid: true,
    form: {
      name: '',
      type: '',
      formattedCredentials: ''
    }
  }),

  watch: {
    form: {
      handler (n) {
        if (n.formattedCredentials) {
          this.formatJson()
        } else {
          this.isJsonValid = true
        }
      },
      deep: true
    }
  },

  methods: {
    async saveNewEmailConf () {
      if (this.$refs['new-email-conf'].validate()) {
        this.loading = true
        this.form.formattedCredentials = JSON.parse(
          this.form.formattedCredentials
        )
        await apiService
          .post('/admin-add-email-configuration', this.form)
          .then(() => {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Email configuration added successfully'
            })
            this.$emit('close')
            this.$emit('refresh')
          })
          .catch((error) => {
            swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    formatJson () {
      try {
        const parsedJson = JSON.parse(this.form.formattedCredentials)
        this.form.formattedCredentials = JSON.stringify(parsedJson, null, 2)
        this.isJsonValid = true
      } catch (error) {
        this.isJsonValid = false
        this.invalidFormatMessafe = 'Invalid JSON: ' + error
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style>
</style>
