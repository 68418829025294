<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="emailOptions"
      sort-by="calories"
      class="elevation-1"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Email Configuration</v-toolbar-title>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="ml-1"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <span>
              Email configurations are used to send emails. You can just enable
              one configuration at a time.
            </span>
          </v-tooltip>

          <v-btn @click="getEmailConfs" icon>
            <v-icon dark>mdi-sync</v-icon>
          </v-btn>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- <span class="text-no-wrap grey lighteen-4 white--text px-2">
            {{
              enabledEmailConf
                ? enabledEmailConf.name +
                  ' service is enabled for sending emails'
                : 'No email configuration enabled'
            }}
          </span> -->
          <v-spacer></v-spacer>
          <v-btn outlined  color="indigo" @click="openNewEmailConfDialog">
            <v-icon> mdi-plus </v-icon> New Email Configuration
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.testService="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :loading="item.loadingCheckEmailService"
              icon
              @click="testEmailService(item)"
              color="primary"
              small
              v-bind="attrs"
              v-on="on"
              ><v-icon>mdi-update</v-icon></v-btn
            >
          </template>
          <span>Test {{ item.name }} Service</span>
        </v-tooltip>

        <v-chip outlined small dark label :color="checkColor(item.statusText)">
          {{ item.statusText }}
          <v-icon
            v-if="item.statusText === 'Running'"
            small
            class="ml-1 rotate-icon"
            >mdi-cog-outline</v-icon
          >
        </v-chip>
      </template>

      <template v-slot:item.isEnabled="{ item }">
        <div>
          <v-chip
            small
            label
            outlined
            :disabled="!item.isEnabled"
            :color="item.isEnabled ? 'green darken-1' : 'grey darken-3'"
            dark
            >{{ item.isEnabled ? 'Enabled' : 'Disabled' }}</v-chip
          >
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon class="mr-1" color="red" @click="deleteItem(item)">
          mdi-delete
        </v-icon>

        <v-icon
          color="primary"
          :disabled="item.isEnabled"
          @click="enableItem(item)"
          >mdi-checkbox-marked-circle-auto-outline</v-icon
        >
      </template>
    </v-data-table>

    <new-email-conf
      v-if="showNewEmailConf"
      :showNewEmailConf="showNewEmailConf"
      @close="closeNewEmailConfDialog"
      @refresh="getEmailConfs"
    ></new-email-conf>

    <edit-email-conf
      v-if="showEditEmailConf"
      :editFormProps="selectedEmailConfItem"
      :showEditEmailConfProps="showEditEmailConf"
      @close="closeEditEmailConfDialog"
      @refresh="getEmailConfs"
    >
    </edit-email-conf>
  </v-container>
</template>

<script>
import EditEmailConf from './EditEmailConf.vue'
import NewEmailConf from './NewEmailConf.vue'
import { apiService } from '../../../../services/api.service'
import Swal from 'sweetalert2'
export default {
  components: { NewEmailConf, EditEmailConf },
  name: 'EmailSettings',
  computed: {
    enabledEmailConf () {
      return this.emailOptions.find((item) => item.isEnabled)
    }
  },
  data () {
    return {
      showNewEmailConf: false,
      showEditEmailConf: false,
      selectedEmailConfItem: null,
      testServiceLoading: false,
      isServiceWorking: false,
      loading: false,
      emailOptions: [],
      headers: [
        { text: '', value: 'isEnabled' },
        { text: 'Test Service', value: 'testService' },
        {
          text: 'Name',
          align: 'start',
          value: 'name'
        },
        { text: 'Type', value: 'type' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ]
    }
  },

  created () {
    this.getEmailConfs()
  },
  methods: {
    async getEmailConfs () {
      this.loading = true
      await apiService
        .get('admin-email-configurations')
        .then((res) => {
          this.emailOptions = res.data.data
        })
        .finally(() => (this.loading = false))
    },

    async enableItem (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to enable this email configuration!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, enable it!',
        preConfirm: async () => {
          return await apiService
            .put(`admin-enable-email-configuration/${item.id}`)
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text:
                  'Error enabling email configuration' ||
                  err.response.data.message
              })
            })
        }
      }).then(async (result) => {
        if (result.value) {
          this.getEmailConfs()
          Swal.fire(
            'Enabled!',
            'Your email configuration has been enabled.',
            'success'
          )
        }
      })
    },

    async testEmailService (item) {
      item.loadingCheckEmailService = true
      item.status = 'Testing...'
      await apiService
        .get(`admin-test-email/${item.id}`)
        .then((res) => {
          item.statusText = res.data.data ? 'Success' : 'Test failed.'
        })
        .catch((err) => {
          item.statusText = 'Test failed.'
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.response.data.message
          })
        })
        .finally(() => {
          item.loadingCheckEmailService = false
        })
    },

    async deleteItem (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this email configuration!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        preConfirm: async () => {
          return await apiService
            .delete(`admin-delete-email-configuration/${item.id}`)
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text:
                  'Error deleting email configuration' ||
                  err.response.data.message
              })
            })
        }
      }).then(async (result) => {
        if (result.value) {
          this.getEmailConfs()
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        }
      })
    },
    checkColor (status) {
      if (status === 'Success') {
        return 'green'
      } else if (status === 'Testing...') {
        return 'red'
      } else {
        return 'grey'
      }
    },
    openNewEmailConfDialog () {
      this.showNewEmailConf = true
    },
    closeNewEmailConfDialog () {
      this.showNewEmailConf = false
    },
    editItem (item) {
      this.selectedEmailConfItem = item
      this.showEditEmailConf = true
    },
    closeEditEmailConfDialog () {
      this.showEditEmailConf = false
    }
  }
}
</script>

<style>
</style>
